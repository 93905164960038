import React from 'react';
import { Heart, Brain, Leaf } from 'lucide-react';

const AboutSection = () => {
  return (
    <section id = "about" className="bg-white py-16" >
      <div className="container mx-auto px-4">
        <div className="flex flex-col lg:flex-row items-center gap-12">
          {/* Left side - Image and Specialties */}
          <div className="w-full lg:w-1/2">
            <div className="rounded-lg p-6 overflow-hidden shadow-xl">
              <img
                src="https://headway.co/cdn-cgi/image/width=500,quality=100,format=auto,fit=contain/https://assets.headway.co/provider_photos/99169/db5d7492-741e-11ef-8b92-0a58a9feac02-99169-1726486134775.jpeg"
                alt="Dr. Velma Henn"
                className="w-full h-full object-cover"
              />
            </div>
            <div className="mt-8 grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="p-4 bg-blue-50 rounded-lg text-center">
                <Heart className="w-8 h-8 mx-auto text-blue-600 mb-2" />
                <h3 className="text-lg font-semibold mb-1">Holistic Care</h3>
                <p className="text-sm text-gray-600">Integrative mental health approach</p>
              </div>
              <div className="p-4 bg-green-50 rounded-lg text-center">
                <Brain className="w-8 h-8 mx-auto text-green-600 mb-2" />
                <h3 className="text-lg font-semibold mb-1">Specialized Focus</h3>
                <p className="text-sm text-gray-600">Hormone & mental health expert</p>
              </div>
              <div className="p-4 bg-purple-50 rounded-lg text-center">
                <Leaf className="w-8 h-8 mx-auto text-purple-600 mb-2" />
                <h3 className="text-lg font-semibold mb-1">Lifestyle Integration</h3>
                <p className="text-sm text-gray-600">Nutrition & wellness emphasis</p>
              </div>
            </div>
          </div>

          {/* Right side - Text Content */}
          <div className="w-full lg:w-1/2">
            <h2 className="text-3xl font-bold mb-6 text-gray-800">Meet Velma Henn, PMHNP</h2>
            <div className="space-y-4 text-gray-600">
              <p className="leading-relaxed">
                Welcome! I'm Velma Henn, a Psychiatric Mental Health Nurse Practitioner with over 26 years of nursing experience and 4 years as a dedicated nurse practitioner. My journey in healthcare has been shaped by a commitment to holistic, compassionate care that addresses not only mental health challenges but also the lifestyle factors that contribute to overall well-being.
              </p>
              
              <p className="leading-relaxed">
                At Transitions Behavioral Health, I specialize in treating adolescents, adults, and professionals, with a particular focus on integrating holistic practices, nutritional psychiatry, and evidence-based therapies like Cognitive Behavioral Therapy (CBT). My goal is to help clients overcome mental health challenges by optimizing both physical and mental health, blending traditional treatment options with a focus on diet, nutrition, and lifestyle.
              </p>

              <p className="leading-relaxed">
                Additionally, I have a specialized interest in the mental health impacts of menopause and hormone replacement therapy (HRT), recognizing how hormonal shifts can play a pivotal role in emotional well-being. Whether we're addressing anxiety, depression, bipolar disorder, or insomnia, I aim to provide personalized, empathetic care that meets each client's unique needs.
              </p>

              <p className="leading-relaxed">
                Outside of work, you'll often find me walking my three Australian Shepherds or fishing – activities that keep me grounded and remind me of the importance of balance in life. I look forward to helping you on your journey toward mental wellness with a comprehensive approach designed just for you.
              </p>
            </div>

            <div className="mt-8">
             
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;