import React from 'react';
import { ExternalLink } from 'lucide-react';

const PartnershipSection = () => {
  return (
    <section className="py-8 bg-gradient-to-br from-blue-50 to-white">
      <div className="container mx-auto px-4">
        <div className=" mx-auto">
          <div className="bg-white rounded-2xl shadow-sm border border-gray-100 p-8">
            <div className="flex flex-col md:flex-row items-center justify-between gap-6">
              {/* Content Side */}
              <div className="text-center md:text-left">
                <h2 className="text-2xl font-bold text-gray-800 mb-2">
                  Streamlined Care with Headway
                </h2>
                <p className="text-gray-600 mb-4">
                  We've partnered with Headway to make mental healthcare more accessible 
                  and affordable while simplifying the insurance and booking process.
                </p>
                <a
                  href="https://headway.co/providers/velma-henn"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center text-blue-600 hover:text-blue-700 
                    font-medium transition-colors duration-200"
                >
                  Book an appointment on Headway instead
                  <ExternalLink className="w-4 h-4 ml-2" />
                </a>
              </div>

              {/* Logo Side with Benefits */}
              <div className="flex flex-col items-center space-y-4">
                <div className="rounded-xl">
                  {/* Replace with actual Headway logo */}
                  <img
                    src="https://mma.prnewswire.com/media/2466641/Headway_Logo_Green_Logo.jpg?p=facebook"
                    alt="Headway Logo"
                    className="h-20 w-auto object-contain"
                  />
                </div>
                <div className="flex flex-wrap justify-center gap-3">
                  <span className="inline-flex items-center px-3 py-1 rounded-full text-sm 
                    bg-blue-50 text-blue-700 font-medium">
                    Verify Insurance Instantly
                  </span>
                  <span className="inline-flex items-center px-3 py-1 rounded-full text-sm 
                    bg-green-50 text-green-700 font-medium">
                    Easy Scheduling
                  </span>
                  <span className="inline-flex items-center px-3 py-1 rounded-full text-sm 
                    bg-purple-50 text-purple-700 font-medium">
                    Transparent Pricing
                  </span>
                </div>
              </div>
            </div>

            {/* Bottom Benefits Grid - Mobile Only */}
            <div className="mt-6 pt-6 border-t border-gray-100 md:hidden">
              <div className="grid grid-cols-1 gap-4">
                <div className="flex items-center gap-3">
                  <div className="w-10 h-10 rounded-full bg-blue-50 flex items-center justify-center">
                    <span className="text-blue-600 text-lg">✓</span>
                  </div>
                  <div>
                    <h3 className="font-medium text-gray-900">Simple Insurance Verification</h3>
                    <p className="text-sm text-gray-500">Check your coverage in minutes</p>
                  </div>
                </div>
                <div className="flex items-center gap-3">
                  <div className="w-10 h-10 rounded-full bg-green-50 flex items-center justify-center">
                    <span className="text-green-600 text-lg">✓</span>
                  </div>
                  <div>
                    <h3 className="font-medium text-gray-900">Convenient Booking</h3>
                    <p className="text-sm text-gray-500">Book appointments online easily</p>
                  </div>
                </div>
                <div className="flex items-center gap-3">
                  <div className="w-10 h-10 rounded-full bg-purple-50 flex items-center justify-center">
                    <span className="text-purple-600 text-lg">✓</span>
                  </div>
                  <div>
                    <h3 className="font-medium text-gray-900">Clear Cost Information</h3>
                    <p className="text-sm text-gray-500">Know your costs upfront</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PartnershipSection;