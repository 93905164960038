import React from "react";
import { ArrowRight, Phone } from "lucide-react";

const HeroSection = () => {
  return (
    <div className="bg-white">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
          {/* Left Column - Content */}
          <div className="space-y-8">
            <div className="space-y-4">
              <h1 className="text-4xl md:text-5xl font-bold text-gray-900 leading-tight">
                Your Journey to Better Mental Health Starts Here
              </h1>
              <p className="text-lg md:text-xl text-gray-600 leading-relaxed">
                At Transitions Behavioral Health, we provide compassionate,
                evidence-based mental health services tailored to your unique
                needs. Our experienced team is here to support you every step of
                the way.
              </p>
            </div>

            <div className="space-y-4 md:space-y-0 md:space-x-4 flex flex-col md:flex-row">
              <button
                onClick={() => (window.location.href = "#contact")}
                className="inline-flex items-center justify-center px-6 py-3 border border-transparent 
                         text-base font-medium rounded-md text-white bg-blue-2 
                         transition-colors duration-200 shadow-sm"
              >
                <Phone className="w-5 h-5 mr-2" />
                Schedule a Consultation
              </button>

              <button
                onClick={() => (window.location.href = "https://headway.co/providers/velma-henn")}
                className="inline-flex items-center justify-center px-6 py-3 border border-gray-300 
                         text-base font-medium rounded-md text-white bg-[#12A965] hover:bg-gray-50 hover:text-black
                         transition-colors duration-200 shadow-sm"
              >
                Book with Headway
                <ArrowRight className="w-5 h-5 ml-2" />
              </button>
            </div>

            {/* Key Features */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 pt-4">
              
              
              
              {/* <div className="flex items-start space-x-3">
                <div className="flex-shrink-0">
                  <div className="w-8 h-8 rounded-full bg-blue-2 flex items-center justify-center">
                    <span className="text-white text-lg">✓</span>
                  </div>
                </div>
                <div>
                  <h3 className="text-lg font-medium text-gray-900">
                    Licensed Therapists
                  </h3>
                  <p className="text-gray-500">
                    Experienced, certified professionals
                  </p>
                </div>
              </div> */}

              


            </div>
          </div>

          {/* Right Column - Image/Visual */}
          <div className="relative hidden md:block">
            {/* Decorative background elements */}
            <div className="absolute inset-0 bg-white rounded-xl" />
            <div className="absolute -inset-4">
              <div className="w-full h-full rotate-3 transform rounded-xl bg-white opacity-30" />
            </div>

            {/* Replace this div with your actual image/logo */}
            <div className="relative aspect-square rounded-xl bg-white flex items-center justify-center">
              {/* You can replace this with: <img src={yourImage} alt="Transitions BH Illustration" /> */}
              <div className="text-center text-gray-400">
                <p className="text-sm">Place for</p>
                <p className="text-sm">Hero Image/Illustration</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
