import React, { useState } from 'react';
import { ChevronDown } from 'lucide-react';

const FAQPage = () => {
  const [openItem, setOpenItem] = useState(null);

  const faqs = [
    {
      id: 1,
      question: "What services do you offer?",
      answer: "We provide comprehensive mental health care including initial psychiatric assessments, medication management, and therapy sessions. Our approach integrates holistic care with a specialized focus on nutritional psychiatry to support overall mental well-being."
    },
    {
      id: 2,
      question: "Who is eligible for treatment?",
      answer: "We serve adolescents (ages 14+), adults, and older adults. We treat various mental health conditions including anxiety, panic disorder, depression, bipolar disorder, and insomnia."
    },
    {
      id: 3,
      question: "What insurance plans do you accept?",
      answer: "We accept several major insurance providers including Aetna, Cigna, Blue Cross Blue Shield, United, and Oscar Health. For private pay clients, our rates are $200 for the initial visit and $120 for each follow-up visit."
    },
    {
      id: 4,
      question: "How does the initial assessment process work?",
      answer: "For adolescent clients, initial assessments are conducted over two visits to ensure thorough evaluation. For all clients, a follow-up appointment is scheduled within two weeks to review and assess the initial treatment's effectiveness."
    },
    {
      id: 5,
      question: "Do you offer telehealth services?",
      answer: "Yes, we offer both in-person and telehealth sessions to accommodate diverse needs. All virtual visits are conducted via a HIPAA-compliant telehealth platform to ensure privacy and security."
    },
    {
      id: 6,
      question: "What is your approach to nutritional psychiatry?",
      answer: "Based on lab results, we provide personalized recommendations on dietary changes and nutritional supplements that may enhance mental health. This is part of our holistic approach to mental well-being."
    },
    {
      id: 7,
      question: "How do you handle patient privacy?",
      answer: "All sessions are strictly confidential. For telehealth visits, we use a HIPAA-compliant platform to ensure privacy. We maintain secure electronic health records and follow all privacy regulations."
    },
    {
      id: 8,
      question: "What additional resources do you provide?",
      answer: "We offer supplementary resources outside of sessions, including lifestyle recommendations, reading materials, and video resources to support your ongoing mental health journey."
    }
  ];

  const toggleItem = (id) => {
    setOpenItem(openItem === id ? null : id);
  };

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Frequently Asked Questions
          </h1>
          <p className="text-lg text-gray-600">
            Find answers to common questions about our services and processes
          </p>
        </div>

        <div className="space-y-4">
          {faqs.map((faq) => (
            <div
              key={faq.id}
              className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden"
            >
              <button
                className="w-full text-left px-6 py-4 flex justify-between items-center hover:bg-gray-50 transition-colors duration-200"
                onClick={() => toggleItem(faq.id)}
                aria-expanded={openItem === faq.id}
              >
                <span className="text-lg font-medium text-gray-900">
                  {faq.question}
                </span>
                <ChevronDown
                  className={`w-5 h-5 text-gray-500 transition-transform duration-200 ${
                    openItem === faq.id ? 'rotate-180' : ''
                  }`}
                />
              </button>
              {openItem === faq.id && (
                <div className="px-6 py-4 bg-gray-50 border-t border-gray-200">
                  <p className="text-gray-600 leading-relaxed">{faq.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="mt-12 text-center">
          <p className="text-gray-600">
            Still have questions?{' '}
            <a href="/#contact" className="text-blue-600 hover:text-blue-800 font-medium">
              Contact us
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default FAQPage;