import React, { useState, useRef } from 'react';
import { User, Phone, Mail, AlertCircle, Clock, Users, Video, MessageSquare } from 'lucide-react';
import emailjs from '@emailjs/browser';

const ContactForm = () => {
  const form = useRef();
  const [formData, setFormData] = useState({
    fullName: '',
    phone: '',
    email: '',
    consent: null
  });

  const [errors, setErrors] = useState({});
  const [submitStatus, setSubmitStatus] = useState({ type: '', message: '' });

  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.fullName.trim()) {
      newErrors.fullName = 'Full name is required';
    }
    
    if (!formData.phone.trim()) {
      newErrors.phone = 'Phone number is required';
    } else if (!/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(formData.phone)) {
      newErrors.phone = 'Please enter a valid phone number';
    }
    
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Please enter a valid email address';
    }
    
    if (formData.consent === null) {
      newErrors.consent = 'Please select whether you consent to receive communications';
    } else if (formData.consent === false) {
      newErrors.consent = 'We require your consent to process your request';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (validateForm()) {
      setSubmitStatus({ type: 'info', message: 'Sending your message...' });
      
      // Format the data for the template
      const templateParams = {
        submission_date: new Date().toLocaleString(),
        fullName: formData.fullName,
        phone: formData.phone,
        email: formData.email,
        consent: formData.consent ? 'Yes, consented to communications' : 'No consent provided'
      };
  
      emailjs
        .send(
          'service_yxvlkx5',
          'template_uh77o09',
          templateParams,
          {
            publicKey: '9VatNfYQe5-8wXxga',
          }
        )
        .then(
          () => {
            setSubmitStatus({
              type: 'success',
              message: 'Thank you for contacting us! We will get back to you soon.'
            });
            setFormData({
              fullName: '',
              phone: '',
              email: '',
              consent: null
            });
          },
          (error) => {
            console.error('EmailJS Error:', error);
            setSubmitStatus({
              type: 'error',
              message: 'There was an error sending your message. Please try again later.'
            });
          }
        );
    }
  };

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'radio' ? value === 'yes' : value
    }));
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
  };

  return (
    <section id= "contact" className="py-16 bg-gradient-to-br from-gray-50 to-blue-50">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row gap-8 mx-auto">
          {/* Left side - Contact Information */}
          <div className="">
            <div className="sticky top-8">
              <div className="bg-white rounded-2xl shadow-lg p-8 mb-6">
                <h3 className="text-xl font-bold text-gray-800 mb-6">Connect With Us</h3>
                <div className="space-y-6">
                  <div className="flex items-start">
                    <div className="bg-blue-100 p-3 rounded-lg">
                      <Clock className="w-6 h-6 text-blue-600" />
                    </div>
                    <div className="ml-4">
                      <h4 className="text-sm font-semibold text-gray-800">Office Hours</h4>
                      <p className="text-sm text-gray-600 mt-1">Mon-Wed: 8:00 AM - 5:00 PM</p>
                      <p className="text-sm text-gray-600 mt-1">Thu: 1:00 PM - 7:00 PM</p>
                      <p className="text-sm text-gray-600 mt-1">Fri-Sat: 8:00 AM - 12:00 PM</p>
                      <p className="text-sm text-gray-600">Sun: Closed</p>
                    </div>
                  </div>

                  <div className="space-y-4">
                    <div className="flex items-start">
                      <div className="bg-green-100 p-3 rounded-lg">
                        <Users className="w-6 h-6 text-green-600" />
                      </div>
                      <div className="ml-4">
                        <h4 className="text-sm font-semibold text-gray-800">In-Person Care</h4>
                        <p className="text-sm text-gray-600 mt-1">
                          Dynamic locations throughout Central Texas
                        </p>
                        <span className="inline-block mt-1 text-xs px-2 py-1 bg-green-50 text-green-700 rounded-full">
                          Multiple locations available
                        </span>
                      </div>
                    </div>

                    <div className="flex items-start">
                      <div className="bg-indigo-100 p-3 rounded-lg">
                        <Video className="w-6 h-6 text-indigo-600" />
                      </div>
                      <div className="ml-4">
                        <h4 className="text-sm font-semibold text-gray-800">Virtual Care</h4>
                        <p className="text-sm text-gray-600 mt-1">
                          Secure telehealth sessions available
                        </p>
                        <span className="inline-block mt-1 text-xs px-2 py-1 bg-indigo-50 text-indigo-700 rounded-full">
                          Available statewide
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="flex items-start">
                    <div className="bg-purple-100 p-3 rounded-lg">
                      <MessageSquare className="w-6 h-6 text-purple-600" />
                    </div>
                    <div className="ml-4">
                      <h4 className="text-sm font-semibold text-gray-800">Response Time</h4>
                      <p className="text-sm text-gray-600 mt-1">We typically respond within 24 business hours</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-blue-2 rounded-2xl shadow-lg p-8 text-white">
                <h3 className="text-xl font-bold mb-4">Emergency Services</h3>
                <p className="text-sm mb-4">
                  If you are experiencing a mental health emergency or crisis, please contact emergency services immediately:
                </p>
                <ul className="space-y-2 text-sm">
                  <li>• National Crisis Hotline: 988</li>
                  <li>• Emergency Services: 911</li>
                  <li>• Local Crisis Line: (555) 555-5555</li>
                </ul>
              </div>
            </div>
          </div>

          {/* Right side - Contact Form */}
          <div className="lg:w-2/3">
            <div className="bg-white rounded-2xl shadow-lg p-8">
              <div className="mb-8">
                <h2 className="text-3xl font-bold text-gray-800 mb-4">Contact Us</h2>
                <p className="text-gray-600">
                  Thank you for reaching out to Transitions Behavioral Health! Please complete the form below, 
                  and we'll get back to you as soon as possible.
                </p>
              </div>

              {submitStatus.message && (
                <div className={`mb-6 p-4 rounded-lg ${
                  submitStatus.type === 'success' ? 'bg-green-50 text-green-700' :
                  submitStatus.type === 'error' ? 'bg-red-50 text-red-700' :
                  'bg-blue-50 text-blue-700'
                }`}>
                  <p className="text-sm">{submitStatus.message}</p>
                </div>
              )}

              <form ref={form} onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label className="flex items-center text-gray-700 text-sm font-medium mb-2">
                    <User className="w-4 h-4 mr-2" />
                    Full Name
                  </label>
                  <input
                    type="text"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleChange}
                    className={`w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent
                      ${errors.fullName ? 'border-red-500' : 'border-gray-300'}`}
                    placeholder="Enter your full name"
                  />
                  {errors.fullName && (
                    <p className="mt-1 text-sm text-red-500 flex items-center">
                      <AlertCircle className="w-4 h-4 mr-1" />
                      {errors.fullName}
                    </p>
                  )}
                </div>

                <div>
                  <label className="flex items-center text-gray-700 text-sm font-medium mb-2">
                    <Phone className="w-4 h-4 mr-2" />
                    Cell Phone Number
                  </label>
                  <input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className={`w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent
                      ${errors.phone ? 'border-red-500' : 'border-gray-300'}`}
                    placeholder="(123) 456-7890"
                  />
                  {errors.phone && (
                    <p className="mt-1 text-sm text-red-500 flex items-center">
                      <AlertCircle className="w-4 h-4 mr-1" />
                      {errors.phone}
                    </p>
                  )}
                </div>

                <div>
                  <label className="flex items-center text-gray-700 text-sm font-medium mb-2">
                    <Mail className="w-4 h-4 mr-2" />
                    Email Address
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className={`w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent
                      ${errors.email ? 'border-red-500' : 'border-gray-300'}`}
                    placeholder="your.email@example.com"
                  />
                  {errors.email && (
                    <p className="mt-1 text-sm text-red-500 flex items-center">
                      <AlertCircle className="w-4 h-4 mr-1" />
                      {errors.email}
                    </p>
                  )}
                </div>

                {/* Updated Consent Box */}
                <div className="bg-blue-50 p-4 rounded-lg">
                  <div className="space-y-2">
                    <p className="text-sm text-gray-700">
                      Do you consent to receive communications from Transitions Behavioral Health via SMS text and email? 
                      We may use this information to respond to your inquiry, share appointment information, or provide updates 
                      related to your care. Message and data rates may apply. You can reply STOP at any time to opt out of messages.
                    </p>
                    
                    <div className="flex items-center space-x-6">
                      <label className="flex items-center space-x-2">
                        <input
                          type="radio"
                          name="consent"
                          value="yes"
                          checked={formData.consent === true}
                          onChange={handleChange}
                          className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                        />
                        <span className="text-sm text-gray-700">Yes, I consent</span>
                      </label>
                      
                      <label className="flex items-center space-x-2">
                        <input
                          type="radio"
                          name="consent"
                          value="no"
                          checked={formData.consent === false}
                          onChange={handleChange}
                          className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                        />
                        <span className="text-sm text-gray-700">No, I do not consent</span>
                      </label>
                    </div>
                  </div>

                  {errors.consent && (
                    <p className="mt-2 text-sm text-red-500 flex items-center">
                      <AlertCircle className="w-4 h-4 mr-1" />
                      {errors.consent}
                    </p>
                  )}
                </div>

                <div>
                  <button
                    type="submit"
                    className="w-full bg-blue-2 text-white px-8 py-3 rounded-lg hover:bg-blue-700 
                      transition-colors duration-200 font-medium text-lg"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;