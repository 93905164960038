import React from 'react';
import { Phone, Mail, MapPin, Facebook, Instagram, Linkedin } from 'lucide-react';
import logo from '../assets/TransitionsBH.png'

const Footer = () => {
  return (
    <footer className="bg-white text-white">
      {/* Main Footer Content */}
      <div className="container mx-auto px-4 py-12">
        <div className="flex flex-col lg:flex-row justify-between space-y-8 lg:space-y-0">
          {/* Logo and About Section */}
          <div className="lg:w-1/3 pr-8">
            <div className="mb-6">
              <img
                src= {logo}
                alt="Transitions Behavioral Health Logo"
                className="h-[100px]  object-contain"
              />
            </div>
            <p className="text-gray-600 mb-6">
              Dedicated to providing compassionate mental health care through holistic, 
              evidence-based approaches that address both mind and body.
            </p>
            <div className="flex space-x-4">
              <a href="#" className="text-gray-600 hover:text-blue-3 transition-colors">
                <Facebook className="w-5 h-5" />
              </a>
              <a href="https://www.instagram.com/tbh_rgv?igsh=N3MzdXVvYzRmaTB5" className="text-gray-600 hover:text-blue-3 transition-colors">
                <Instagram className="w-5 h-5 " />
              </a>
              <a href="#" className="text-gray-600 hover:text-blue-3 transition-colors">
                <Linkedin className="w-5 h-5" />
              </a>
            </div>
          </div>

          {/* Quick Links */}
          <div className="lg:w-1/4">
            <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li>
                <a href="/resources" className="text-gray-600 hover:text-blue-3 transition-colors">
                 Resources
                </a>
              </li>
              <li>
                <a href="faqs" className="text-gray-600 hover:text-blue-3 transition-colors">
                 FAQs
                </a>
              </li>
              <li>
                <a href="/privacy-policy" className="text-gray-600 hover:text-blue-3 transition-colors">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="#" className="text-gray-600 hover:text-blue-3 transition-colors">
                  Blogs
                </a>
              </li>
            </ul>
          </div>

          {/* Contact Information */}
          <div className="lg:w-1/3">
            <h3 className="text-lg font-semibold mb-4">Contact Us</h3>
            <ul className="space-y-4">
              
              <li className="flex items-center">
                <Phone className="w-5 h-5 text-blue-2 mr-3 flex-shrink-0 text-blue-3" />
                <a href="tel:+19565242003" className="text-gray-600 hover:text-blue-3 transition-colors">
                  (956) 524-2003
                </a>
              </li>
              <li className="flex items-center">
                <Mail className="w-5 h-5 text-blue-2 mr-3 flex-shrink-0 text-blue-3" />
                <a href="mailto:info@transitionsbehavioralhealth.com" className="text-gray-600 hover:text-blue-2 transition-colors">
                  info@transitionsbehavioralhealth.com
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Bottom Bar with Copyright and Attribution */}
      <div className="border-t border-gray-200">
        <div className="container mx-auto px-4 py-6">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            <div className="text-sm text-gray-600">
              © {new Date().getFullYear()} Transitions Behavioral Health. All rights reserved.
            </div>
            <div className="text-sm text-gray-500 flex items-center space-x-2">
              <span>Website designed by</span>
              <a 
                href="https://ouitrend.com" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-blue-3 hover:text-blue-300 transition-colors font-medium"
              >
                OuiTrend Marketing Agency
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;