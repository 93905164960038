import React, { useState } from 'react';
import { Book, FileText, Video, Globe } from 'lucide-react';

const ResourcesPage = () => {
  const [activeCategory, setActiveCategory] = useState('all');
  
  const categories = [
    { id: 'all', label: 'All Resources', icon: null },
    { id: 'books', label: 'Books', icon: Book },
    { id: 'articles', label: 'Articles', icon: FileText },
    { id: 'videos', label: 'Videos', icon: Video },
    { id: 'websites', label: 'Websites', icon: Globe }
  ];

  const resources = {
    books: {
      "Anxiety Management": {
        items: [
          {
            title: "The Anxiety and Phobia Workbook",
            author: "Edmund J. Bourne",
            description: "A comprehensive guide offering practical strategies and exercises for managing anxiety and phobias."
          },
          {
            title: "Dare: The New Way to End Anxiety and Stop Panic Attacks",
            author: "Barry McDonagh",
            description: "Introduces the DARE technique for proactively handling anxiety and panic."
          }
        ]
      },
      "Nutrition and Mental Health": {
        items: [
          {
            title: "Eat to Beat Depression and Anxiety",
            author: "Drew Ramsey, MD",
            description: "Explores how nutrition can be a tool to support mental health, featuring foods that promote brain health."
          },
          {
            title: "The Mind-Gut Connection",
            author: "Emeran Mayer, MD",
            description: "Examines the gut-brain connection and how diet impacts mental well-being."
          }
        ]
      },
      "Lifestyle Changes": {
        items: [
          {
            title: "Atomic Habits",
            author: "James Clear",
            description: "A practical guide for creating positive, lasting habits to support mental and physical wellness."
          },
          {
            title: "The Body Keeps the Score",
            author: "Bessel van der Kolk, MD",
            description: "Delves into the effects of trauma on the body and mind and the healing power of mind-body practices."
          }
        ]
      }
    },
    articles: {
      "Managing Anxiety": {
        items: [
          {
            title: "Mindfulness Practices to Reduce Anxiety",
            description: "Discusses mindfulness techniques that can help reduce anxiety."
          },
          {
            title: "Cognitive Behavioral Therapy Techniques for Anxiety",
            description: "An overview of CBT strategies to help manage anxious thoughts and behaviors."
          }
        ]
      },
      "Nutrition and Mental Health": {
        items: [
          {
            title: "The Link Between Nutrition and Mental Health",
            description: "Explores how certain nutrients impact mood and mental health."
          },
          {
            title: "How Omega-3s Support Brain Health",
            description: "Discusses the mental health benefits of omega-3 fatty acids, commonly found in fish and certain seeds."
          }
        ]
      }
    },
    videos: {
      "Anxiety and Stress Management": {
        items: [
          {
            title: "How to Make Stress Your Friend",
            author: "Kelly McGonigal",
            description: "A talk on reframing stress in a way that benefits mental health."
          },
          {
            title: "The Power of Vulnerability",
            author: "Brené Brown",
            description: "A compelling talk on courage, vulnerability, and human connection."
          }
        ]
      },
      "Nutrition and Mental Health": {
        items: [
          {
            title: "Food for Thought: How Your Belly Controls Your Brain",
            author: "Ruairi Robertson",
            description: "Explores the gut-brain connection and how our diets impact mental health."
          }
        ]
      }
    },
    websites: [
      {
        title: "National Alliance on Mental Illness (NAMI)",
        description: "Provides resources, educational materials, and support for those living with mental health conditions and their families.",
        url: "https://www.nami.org"
      },
      {
        title: "Mental Health America (MHA)",
        description: "Offers resources, screening tools, and information on mental health conditions, treatment options, and wellness strategies.",
        url: "https://www.mhanational.org"
      },
      {
        title: "American Psychological Association (APA)",
        description: "Offers a wide range of mental health articles, research, and tools for individuals seeking more information about mental health topics.",
        url: "https://www.apa.org"
      },
      {
        title: "The Anxiety and Depression Association of America (ADAA)",
        description: "Specializes in resources for managing anxiety, depression, and related disorders, including self-help tools and research.",
        url: "https://www.adaa.org"
      }
    ]
  };

  const ResourceCard = ({ item }) => (
    <div className="bg-white rounded p-6 transition-all duration-200 hover:shadow-md">
      <h3 className="text-lg font-medium text-gray-900 mb-2">{item.title}</h3>
      {item.author && (
        <p className="text-sm text-gray-600 mb-3">by {item.author}</p>
      )}
      <p className="text-gray-600 text-sm leading-relaxed">{item.description}</p>
      {item.url && (
        <a
          href={item.url}
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block mt-4 text-blue-600 hover:text-blue-800 text-sm font-medium"
        >
          Learn More →
        </a>
      )}
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="max-w-3xl mx-auto text-center mb-12">
          <h1 className="text-3xl font-medium text-gray-900 mb-4">
            Mental Health Resources
          </h1>
          <p className="text-gray-600">
            Explore our carefully curated collection of resources to support your mental health journey
          </p>
        </div>

        {/* Category Navigation */}
        <div className="flex justify-center mb-12">
          <div className="inline-flex gap-2 p-1 bg-white rounded-lg shadow-sm">
            {categories.map(({ id, label, icon: Icon }) => (
              <button
                key={id}
                onClick={() => setActiveCategory(id)}
                className={`flex items-center px-4 py-2 rounded-md text-sm transition-all duration-200 ${
                  activeCategory === id
                    ? 'bg-blue-600 text-white'
                    : 'text-gray-600 hover:bg-gray-100'
                }`}
              >
                {Icon && <Icon className="w-4 h-4 mr-2" />}
                {label}
              </button>
            ))}
          </div>
        </div>

        {/* Resource Sections */}
        <div className="space-y-12">
          {(activeCategory === 'all' || activeCategory === 'books') && (
            <div>
              <h2 className="text-2xl font-medium text-gray-900 mb-8">Recommended Reading</h2>
              {Object.entries(resources.books).map(([category, { items }]) => (
                <div key={category} className="mb-12">
                  <h3 className="text-lg font-medium text-gray-800 mb-6">{category}</h3>
                  <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                    {items.map((book, bookIndex) => (
                      <ResourceCard key={bookIndex} item={book} />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}

          {(activeCategory === 'all' || activeCategory === 'articles') && (
            <div>
              <h2 className="text-2xl font-medium text-gray-900 mb-8">Articles & Research</h2>
              {Object.entries(resources.articles).map(([category, { items }]) => (
                <div key={category} className="mb-12">
                  <h3 className="text-lg font-medium text-gray-800 mb-6">{category}</h3>
                  <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                    {items.map((article, articleIndex) => (
                      <ResourceCard key={articleIndex} item={article} />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}

          {(activeCategory === 'all' || activeCategory === 'videos') && (
            <div>
              <h2 className="text-2xl font-medium text-gray-900 mb-8">Videos & Talks</h2>
              {Object.entries(resources.videos).map(([category, { items }]) => (
                <div key={category} className="mb-12">
                  <h3 className="text-lg font-medium text-gray-800 mb-6">{category}</h3>
                  <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                    {items.map((video, videoIndex) => (
                      <ResourceCard key={videoIndex} item={video} />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}

          {(activeCategory === 'all' || activeCategory === 'websites') && (
            <div>
              <h2 className="text-2xl font-medium text-gray-900 mb-8">Trusted Websites</h2>
              <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                {resources.websites.map((website, index) => (
                  <ResourceCard key={index} item={website} />
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Emergency Resources Banner */}
        <div className="mt-16 bg-blue-50 border border-blue-100 rounded-lg p-6">
          <h2 className="text-lg font-medium text-blue-900 mb-2">Need Immediate Help?</h2>
          <p className="text-blue-700 mb-4">
            If you're experiencing a mental health emergency, please reach out:
          </p>
          <div className="grid gap-4 md:grid-cols-3">
            <div className="bg-white p-4 rounded-md">
              <p className="font-medium text-gray-900">National Crisis Hotline</p>
              <p className="text-blue-600 font-medium">988</p>
            </div>
            <div className="bg-white p-4 rounded-md">
              <p className="font-medium text-gray-900">Emergency Services</p>
              <p className="text-blue-600 font-medium">911</p>
            </div>
            <div className="bg-white p-4 rounded-md">
              <p className="font-medium text-gray-900">Local Crisis Line</p>
              <p className="text-blue-600 font-medium">(555) 555-5555</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResourcesPage;