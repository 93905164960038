import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-blue-50 py-16">
      <div className="container mx-auto px-4 max-w-4xl">
        <div className="bg-white rounded-2xl shadow-lg p-8 md:p-12">
          {/* Header */}
          <div className="mb-8 pb-8 border-b border-gray-200">
            <h1 className="text-3xl font-bold text-gray-800 mb-2">Privacy Policy</h1>
            <p className="text-gray-600">Last Updated: November 20, 2024</p>
          </div>

          {/* Content Sections */}
          <div className="space-y-8">
            {/* Information Collection */}
            <section>
              <h2 className="text-xl font-semibold text-gray-800 mb-4">Information We Collect</h2>
              <p className="text-gray-600 mb-3">When you submit our contact form, we collect:</p>
              <ul className="list-disc list-inside text-gray-600 space-y-2 ml-4">
                <li>Full name</li>
                <li>Email address</li>
                <li>Phone number</li>
                <li>Your consent preferences for communications</li>
              </ul>
            </section>

            {/* Information Usage */}
            <section>
              <h2 className="text-xl font-semibold text-gray-800 mb-4">How We Use Your Information</h2>
              <ul className="list-disc list-inside text-gray-600 space-y-2 ml-4">
                <li>Contact you regarding your inquiry</li>
                <li>Send you appointment information and updates</li>
                <li>Respond to your questions</li>
                <li>Provide information about our services</li>
              </ul>
            </section>

            {/* Communications */}
            <section>
              <h2 className="text-xl font-semibold text-gray-800 mb-4">Communications</h2>
              <div className="bg-blue-50 rounded-lg p-4">
                <p className="text-gray-600 mb-3">By providing your contact information and consent:</p>
                <ul className="list-disc list-inside text-gray-600 space-y-2 ml-4">
                  <li>We may contact you via email or phone</li>
                  <li>You can opt-out of SMS messages by replying "STOP"</li>
                  <li>You can opt-out of emails by following the unsubscribe link</li>
                  <li>We typically respond within 24 business hours</li>
                </ul>
              </div>
            </section>

            {/* Information Protection */}
            <section>
              <h2 className="text-xl font-semibold text-gray-800 mb-4">Information Protection</h2>
              <ul className="list-disc list-inside text-gray-600 space-y-2 ml-4">
                <li>Using secure, encrypted connections</li>
                <li>Limiting access to authorized staff only</li>
                <li>Never selling your information to third parties</li>
                <li>Storing data securely</li>
              </ul>
            </section>

            {/* Contact Information */}
            <section className="bg-gray-50 rounded-lg p-6">
              <h2 className="text-xl font-semibold text-gray-800 mb-4">Contact Us</h2>
              <p className="text-gray-600 mb-3">
                If you have questions about your information or this privacy policy, please contact us at:
              </p>
              <div className="space-y-2">
                <p className="text-gray-600">
                  <span className="font-medium">Email:</span>{" "}
                  <a href="mailto:privacy@transitionsbehavioralhealth.com" className="text-blue-600 hover:text-blue-800">
                    info@transitionsbehavioralhealth.com
                  </a>
                </p>
                <p className="text-gray-600">
                  <span className="font-medium">Phone:</span>{" "}
                  <a href="tel:+15555555555" className="text-blue-600 hover:text-blue-800">
                    (956) 524-2003
                  </a>
                </p>
              </div>
            </section>

            {/* Update Notice */}
            <section className="text-sm text-gray-500 pt-8 border-t border-gray-200">
              <p>
                This privacy policy was last updated on November 20, 2024. We may update this policy from time to time. 
                Any changes will be posted on this page.
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;