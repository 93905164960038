import React from 'react';
import aetna from '../assets/insurance/Aetna-Logo.png'
import bcbs from '../assets/insurance/bcbs-tx.png'
import cigna from '../assets/insurance/Cigna-Logo.png'
import quest from '../assets/insurance/quest.png'
import united from '../assets/insurance/United-Healthcare-Logo.png'

const InsuranceSection = () => {
  // Sample insurance data - replace placeholder images with actual insurance logos
  const insurances = [
    { name: "Blue Cross Blue Shield of Texas", image: bcbs },
    { name: "Aetna", image: aetna },
    { name: "United Healthcare", image: united },
    { name: "Cigna", image: cigna },
    { name: "Quest", image: quest },
  ];

  return (
    <section className="py-8 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="text-center mb-6">
          <h2 className="text-2xl font-semibold text-gray-800">Accepted Insurance Plans</h2>
          <p className="text-gray-600 mt-2">We work with major insurance providers to ensure accessible care</p>
        </div>
        
        <div className="flex flex-wrap justify-center items-center gap-8 md:gap-12">
          {insurances.map((insurance, index) => (
            <div 
              key={index} 
              className="flex flex-col items-center"
            >
              <img
                src={insurance.image}
                alt={`${insurance.name} logo`}
                className="h-12 object-contain grayscale hover:grayscale-0 transition-all duration-300"
              />
              <span className="text-sm text-gray-600 mt-2">{insurance.name}</span>
            </div>
          ))}
        </div>

        <div className="text-center mt-6">
          <p className="text-sm text-gray-500">
            Don't see your insurance? Contact us to verify your coverage.
          </p>
          {/* <button className="mt-3 text-blue-2 hover:text-blue-700 text-sm font-medium">
            Verify Insurance Coverage →
          </button> */}
        </div>
      </div>
    </section>
  );
};

export default InsuranceSection;