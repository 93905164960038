import React from 'react';
import { Construction } from 'lucide-react';

const BlogPage = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="max-w-3xl mx-auto">
          <div className="text-center">
            <h1 className="text-3xl font-medium text-gray-900 mb-4">
              Transitions Blog
            </h1>
            <p className="text-gray-600 mb-8">
              Insights, resources, and updates from our mental health professionals
            </p>
          </div>

          {/* Coming Soon Section */}
          <div className="bg-white rounded-lg shadow-sm p-12 text-center">
            <Construction className="w-16 h-16 text-blue-600 mx-auto mb-6" />
            <h2 className="text-2xl font-medium text-gray-900 mb-4">
              Coming Soon
            </h2>
            <p className="text-gray-600 max-w-md mx-auto mb-8">
              We're currently working on creating valuable content to support your mental health journey. 
              Check back soon for articles, insights, and resources.
            </p>
            
            {/* Placeholder Cards */}
            <div className="grid gap-6 md:grid-cols-2 mt-12">
              {[1, 2, 3, 4].map((item) => (
                <div key={item} className="bg-gray-50 rounded p-6 animate-pulse">
                  <div className="h-4 bg-gray-200 rounded w-3/4 mb-4"></div>
                  <div className="h-4 bg-gray-200 rounded w-1/2 mb-2"></div>
                  <div className="h-4 bg-gray-200 rounded w-5/6"></div>
                </div>
              ))}
            </div>

            {/* Newsletter Signup */}
            <div className="mt-12 pt-12 border-t border-gray-100">
              <h3 className="text-lg font-medium text-gray-900 mb-4">
                Get Notified When We Launch
              </h3>
              <p className="text-gray-600 mb-6">
                Subscribe to receive updates when new articles are published
              </p>
              <div className="flex gap-4 max-w-md mx-auto">
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="flex-1 px-4 py-2 border border-gray-200 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <button className="px-6 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors">
                  Subscribe
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPage;